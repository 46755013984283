import { SVGProps } from "react";

export function FrameCreditSvg(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.23825 5.59052C5.75495 3.58298 7.57732 2.09961 9.74615 2.09961C12.3168 2.09961 14.4007 4.18352 14.4007 6.75415C14.4007 8.80363 13.0761 10.5437 11.2363 11.165M10.9087 10.2451C10.9087 12.8157 8.82479 14.8996 6.25415 14.8996C3.68352 14.8996 1.59961 12.8157 1.59961 10.2451C1.59961 7.67443 3.68352 5.59052 6.25415 5.59052C8.82479 5.59052 10.9087 7.67443 10.9087 10.2451Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
