"use client";

import { Menu, MenuButton, MenuItem, MenuItems, Transition } from "@headlessui/react";
import { PhotoIcon, PlusCircleIcon } from "@heroicons/react/24/outline";
import {
  ArrowRightStartOnRectangleIcon,
  Cog6ToothIcon,
  VideoCameraIcon,
  WalletIcon,
} from "@heroicons/react/24/solid";
import type { Session } from "next-auth";
import { signOut } from "next-auth/react";
import { usePostHog } from "posthog-js/react";
import { Fragment } from "react";
import { Feed } from "./notifications/feed";
import { FrameCreditSvg } from "./svg/frame-credit-svg";

const items = [
  { name: "Create Video", icon: PlusCircleIcon, link: "/create" },
  { name: "My Videos", icon: VideoCameraIcon, link: "/dashboard/videos" },
  { name: "My Images", icon: PhotoIcon, link: "/dashboard/images" },
  { name: "My Plan", icon: WalletIcon, link: "/dashboard/my-plan" },
  { name: "Settings", icon: Cog6ToothIcon, link: "/settings" },
] as const;

function ProfileImage({ user }: Pick<Session, "user">) {
  return (
    <div className="flex size-8 min-w-8 items-center">
      {user?.image ? (
        <img className="inline-block size-8 rounded-full" src={user.image} alt="Profile" />
      ) : (
        <div className="flex size-8 items-center justify-center rounded-full bg-teal-300/50 text-xl">
          {user?.email?.[0]}
        </div>
      )}
    </div>
  );
}

export function HeaderProfile({ user }: Pick<Session, "user">) {
  const posthog = usePostHog();
  return (
    <div className="right-2 top-0 text-right">
      <Menu as="div" className="relative z-10 flex items-center text-left">
        <Feed />

        <MenuButton className="inline-flex w-full justify-center rounded-md p-1 text-sm font-medium hover:bg-black/20">
          <div className="flex w-full justify-between gap-2 px-0">
            <ProfileImage user={user} />

            <div className="hidden items-center gap-1 text-center text-sm text-gray-300 sm:flex">
              <FrameCreditSvg className="h-4 w-4 stroke-gray-300" />

              <span className="hidden sm:inline-block">{user.framesCount.toLocaleString()}</span>
            </div>
          </div>
        </MenuButton>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <MenuItems className="absolute right-0 top-10 z-10 mt-1 w-52 origin-top-right rounded-md bg-slate-800 shadow-lg focus:outline-none">
            <div className="flex w-full gap-2 border-b border-b-slate-600 px-3 py-3">
              <ProfileImage user={user} />

              <div className="overflow-hidden">
                <div className="flex items-center gap-1 text-center text-sm text-gray-300">
                  <FrameCreditSvg className="h-4 w-4 stroke-gray-300" />

                  <span className="inline-block">{user.framesCount.toLocaleString()}</span>
                </div>
                <div
                  className="overflow-hidden overflow-ellipsis stroke-gray-300 text-xs"
                  style={{
                    scrollbarWidth: "thin",
                    scrollbarGutter: "none",
                    scrollbarColor: "gray transparent",
                  }}
                >
                  {user.email}
                </div>
              </div>
            </div>

            <div className="px-1 py-1">
              {items.map((item) => (
                <MenuItem key={item.name}>
                  <a
                    href={item.link}
                    className={`group flex w-full items-center rounded-md px-2 py-2 text-sm hover:bg-slate-700`}
                  >
                    <item.icon className="mr-2 h-5 w-5" aria-hidden="true" />
                    {item.name}
                  </a>
                </MenuItem>
              ))}
              <MenuItem>
                <button
                  className={`group flex w-full items-center rounded-md px-2 py-2 text-sm hover:bg-slate-700`}
                  onClick={() => {
                    posthog.reset();
                    signOut();
                  }}
                >
                  <ArrowRightStartOnRectangleIcon className="mr-2 h-5 w-5" aria-hidden="true" />
                  Log Out
                </button>
              </MenuItem>
            </div>
          </MenuItems>
        </Transition>
      </Menu>
    </div>
  );
}
